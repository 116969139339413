import { Flex, chakra } from "@chakra-ui/react";
import AccountHeader from "./AccountHeader";
import bgPng from "public/bg.png";

const AccountBasic = ({ innerComponent }: { innerComponent: React.ReactElement }) => {
  return (
    <Flex
      h={"var(--chakra-vh)"}
      background="linear-gradient(180deg, #000000 52.92%, rgba(0, 0, 0, 0) 86.16%)"
      flexDir={"column"}
    >
      <chakra.img src={bgPng.src} position="absolute" bottom={"0"} w="100%" />
      <AccountHeader />
      <Flex zIndex={10} flex={1}>
        {innerComponent}
      </Flex>
    </Flex>
  );
};

export default AccountBasic;
