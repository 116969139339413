import { forwardRef, Input, InputProps } from "@chakra-ui/react";

// TODO Discuss with designers and generate input component specifications

export const KaitoInput: React.FC<InputProps & { ref: any }> = forwardRef((props, ref) => {
  return (
    <Input
      backgroundColor="grayBlue.700"
      focusBorderColor="brand.200"
      errorBorderColor="invalid"
      borderColor="grayBlue.500"
      borderWidth="1px"
      fontSize="14px"
      _invalid={{
        boxShadow: "none",
        borderColor: "invalid",
      }}
      _hover={{
        borderColor: "grayBlue.200",
      }}
      _focusVisible={{
        boxShadow: "none",
        borderColor: "brand.200",
      }}
      _placeholder={{
        color: "whiteAlpha.200",
        opacity: "1",
      }}
      ref={ref}
      height={9}
      {...props}
    />
  );
});
