import { Flex, Text, Divider, Box } from "@chakra-ui/react";
import { TermAndConditionModal } from "@/components/TermAndCondition";
import { Privacy } from "@/components/Privacy";

const Footer2 = () => (
  <Flex
    mb={"80px"}
    alignContent="center"
    justifyContent="center"
    color="whiteAlpha.600"
    fontSize={"14px"}
    height="24px"
    lineHeight={"24px"}
  >
    <Text as={"a"} href="https://kaito.ai/about" padding={"0 8px"}>
      About Us
    </Text>
    <Divider orientation="vertical" color="rgba(255, 255, 255, 0.6)" opacity={"1"} height="24px" />
    <Divider orientation="vertical" color="rgba(255, 255, 255, 0.6)" opacity={"1"} height="24px" />
    <Box padding={"0 8px"}>
      <TermAndConditionModal />
    </Box>
    <Divider orientation="vertical" color="rgba(255, 255, 255, 0.6)" opacity={"1"} height="24px" />
    <Box padding={"0 8px"}>
      <Privacy />
    </Box>
  </Flex>
);

export default Footer2;
