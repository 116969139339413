import Link from "next/link";
import { Flex, chakra } from "@chakra-ui/react";
import FullLogo from "@/public/fullLogo.png";
import { getHomepagePath } from "@/utils";

const AccountHeader = () => (
  <Flex
    height="75px"
    width="100vw"
    alignItems="center"
    justifyContent="space-between"
    top="0"
    bg="blackAlpha.800"
    px={"80px"}
    backdropFilter="blur(20px)"
  >
    <Link href={`${getHomepagePath()}`} legacyBehavior>
      <Flex>
        <chakra.img src={FullLogo.src} w="100px" h="20px" cursor={"pointer"} />
      </Flex>
    </Link>
  </Flex>
);

export default AccountHeader;
